<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    class="listing--form-modal action--modal"
    @opened="opened"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <section-loading :show="loading" />
      <section class="top--part">
        <div class="action--image">
          <img
            src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/static/repost.png"
          />
        </div>
        <div class="action--desc">
          <div class="desc--title">
            {{ $t('listing.modal.activate.message1') }}
          </div>
          <div class="single--desc">
            <div class="desc--icon">
              <img src="@/assets/img/icons/calendar.png" />
            </div>
            <div class="desc--text">
              {{ $t('listing.modal.activate.message2') }}
            </div>
          </div>
          <div class="single--desc">
            <div class="desc--icon">
              <img src="@/assets/img/icons/magnifying-glass.png" />
            </div>
            <div class="desc--text">
              {{ $t('listing.modal.activate.message3') }}
            </div>
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
      <section class="modal--container">
        <div class="subtitle--text">
          {{ $t('listing.modal.activate.title') }}
        </div>
        <div class="action--info">
          <div class="info--title">{{ $t('listing.modal.activate.info') }}</div>
          <div class="row">
            <div class="col-6 info--coin">{{ $t('listing.modal.activate.usedCoin') }}</div>
            <div class="col-6 info--my-coin">
              <div class="coin--use">
                {{ `1 ${$t('general.coin')}` }}
              </div>
              <div class="coin--remaining">
                {{ `${$t('listing.modal.activate.yourCoin')} ${totalCoins}` }}
              </div>
            </div>
          </div>
        </div>
        <div class="bottom--part">
          <a class="btn btn-primary" @click="activateListing">
            {{ $t('listing.modal.activate.btn') }}
          </a>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
const SectionLoading = () => import('@/components/content-loading/section-loading');

export default {
  name: 'activate-listing-modal',
  components: {
    SectionLoading,
  },
  computed: {
    ...mapState({
      totalCoins: (state) => state.coins.totalCoins,
    }),
  },
  data() {
    return {
      modalName: 'activate-listing-modal',
      loading: false,
      submitAction: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async beforeOpen(event) {
      if (event.params && event.params.submitAction !== null) {
        this.submitAction = event.params.submitAction;
      }
    },
    async opened() {
      try {
        this.loading = true;
        await this.$store.dispatch('coins/getTotalCoins');
      } finally {
        this.loading = false;
      }
    },
    activateListing() {
      this.close();
      this.$emit('submit');
      if (this.submitAction !== null) {
        this.submitAction();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/utils/variables';
</style>
